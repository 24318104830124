import React, { Component } from 'react'

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <iframe
                title="Privacy Policy"
                key="privacy-policy-iframe-key"
                style={{ minWidth: '100%', minHeight: 'calc(100vh + 64px)', marginTop: -70 }}
                src={`https://www.springworks.in/privacy-policy/`}
                allowFullScreen
            ></iframe>
        )
    }
}
