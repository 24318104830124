import React, { useState, useEffect } from 'react'
import * as S from './style'
import Features from './Features'
import Steps from './Steps'
import GoMobile from './GoMobile'
import { ReactComponent as LogoIcon } from '../../../assets/Icons/Landing/logo.svg'
import BannerImg from '../../../assets/Icons/Landing/free-applicant-tracking-system.svg'
import Achievements from './Achievements'
import Testimonials from './Testimonials'
import WhyUs from './WhyUs'
import Footer from './Footer'
import { Carousel } from 'antd'
import { connect } from 'react-redux'
import { GetPublicToken } from '../../../services/NetworkCalls/LandingPage'
import { DemoButton, StartedButton } from './Components/StaticComponents'
import config from '../../../config'
import { ASSETS_CDN, COMPANY_LOGS } from './constants'
const { isMobileDevice } = config

class LandingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            token: null,
            menu: false,
        }
    }

    componentDidMount() {
        if (!this.props.isLoggedIn) this.getTokenFromAPi()
    }

    getTokenFromAPi = async () => {
        const { token } = await GetPublicToken()
        this.setState({ token })
    }

    showMenu = () => {
        this.setState({ menu: !this.state.menu })
        // menu = !menu
    }

    render() {
        const { accessLevel, token, isLoggedIn } = this.props
        return (
            <RenderComponent
                menu={this.state.menu}
                showMenu={this.showMenu}
                accessLevel={accessLevel}
                token={token || this.state.token}
                isLoggedIn={isLoggedIn}
            />
        )
    }
}

const RenderComponent = ({ menu, showMenu, token, isLoggedIn, accessLevel }) => {
    return (
        <>
            <S.GlobalStyle />
            <S.Header open={menu}>
                <a href="/">
                    <LogoIcon />
                </a>
                <div className="links">
                    <a href="https://www.springworks.in/about-us/">ABOUT US</a>
                    {!isLoggedIn && <a href="/signin/">LOGIN</a>}
                    <a href="https://springrecruit.com/blog/" target="=_blank">
                        BLOG
                    </a>
                    <StartedButton isLoggedIn={isLoggedIn} top accessLevel={accessLevel} />
                </div>
                <div className={`hamburger${menu ? ' open' : ''}`} onClick={() => showMenu()}>
                    {Array.apply(null, { length: 4 }).map((e, i) => (
                        <span key={i}></span>
                    ))}
                </div>
            </S.Header>
            {menu ? (
                <>
                    <S.Menu>
                        <li>
                            <a href="https://www.springworks.in/about-us/">ABOUT US</a>
                        </li>
                        <li>{!isLoggedIn && <a href="/signin/">LOGIN</a>}</li>
                        <li>
                            <a href="https://springrecruit.com/blog/" target="=_blank">
                                BLOG
                            </a>
                        </li>
                    </S.Menu>
                </>
            ) : (
                <S.Main>
                    <S.BannerSection>
                        <div className="content">
                            <S.H1>
                                Applicant Tracking System
                                <br />
                                that is <b>FREE FOREVER</b>
                            </S.H1>
                            <S.Description>
                                Quicker hiring means two things: One, you save time. Two, you hire before others do.
                            </S.Description>
                            <img
                                className="show-sm"
                                src={BannerImg}
                                title="Free Applicant Tracking System"
                                alt="Free Applicant Tracking System"
                            />
                            <div className="buttons">
                                <StartedButton isLoggedIn={isLoggedIn} />
                                {/* <DemoButton /> */}
                            </div>
                        </div>
                        <img
                            className="hide-sm"
                            src={BannerImg}
                            title="Free Applicant Tracking System"
                            alt="Free Applicant Tracking System"
                        />
                    </S.BannerSection>
                    <S.Companies>
                        {isMobileDevice ? (
                            <Carousel autoplay effect="fade">
                                <div>
                                    {[0, 1, 2, 3].map(i => (
                                        <img key={i} src={ASSETS_CDN + COMPANY_LOGS[i]} />
                                    ))}
                                </div>
                                <div>
                                    {[4, 5, 6, 7].map(i => (
                                        <img key={i} src={ASSETS_CDN + COMPANY_LOGS[i]} />
                                    ))}
                                </div>
                                <div>
                                    {[8, 9].map(i => (
                                        <img key={i} src={ASSETS_CDN + COMPANY_LOGS[i]} />
                                    ))}
                                </div>
                            </Carousel>
                        ) : (
                            <Carousel autoplay effect="fade">
                                <div>
                                    {[0, 1, 2, 3].map(i => (
                                        <img key={i} src={ASSETS_CDN + COMPANY_LOGS[i]} />
                                    ))}
                                </div>
                                <div>
                                    {[5, 6, 7, 8].map(i => (
                                        <img key={i} src={ASSETS_CDN + COMPANY_LOGS[i]} />
                                    ))}
                                </div>
                            </Carousel>
                        )}
                    </S.Companies>
                    <Features token={token} isLoggedIn={isLoggedIn} accessLevel={accessLevel} />
                    <Steps isLoggedIn={isLoggedIn} accessLevel={accessLevel} />
                    <GoMobile />
                    <Achievements />
                    <Testimonials isLoggedIn={isLoggedIn} />
                    <WhyUs isLoggedIn={isLoggedIn} accessLevel={accessLevel} />
                    <Footer />
                </S.Main>
            )}
        </>
    )
}

const mapStateToProps = state => ({
    isLoggedIn: state.Auth.idToken !== null,
    accessLevel: (state.Auth.user && state.Auth.user.accessLevel) || 0,
    token: state.Auth.idToken,
})

export default connect(
    mapStateToProps,
    {}
)(LandingPage)
