import React from 'react'
import './style.scss'
import { Link } from 'react-router-dom'

export default class Footer extends React.Component {
    render() {
        return (
            <div className="next-opportunity-footer">
                <span>© Springrecruit {new Date().getFullYear()}</span>
                <span className="next-opportunity-term-conditions">
                    <Link to="/public/terms-and-conditions">Terms & conditions</Link>
                </span>
                <span className="next-opportunity-privacy-policy">
                    <a href="http://springworks.in/privacy-policy">Privacy policy</a>
                </span>
            </div>
        )
    }
}
